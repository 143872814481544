import React from "react";

import CustomLink from "./CustomLink";
import PhoneInput from "./PhoneInput";


class ContactFormContent extends React.Component {
  componentDidUpdate() {
    if (this.props.isDisplayed) {
      this.nameInput.focus()
    }
  }

  render() {
    return (
      <div className="full-width flex-row justc-space-b alitems-stretch
                        s-flex-column s-alitems-center">
        <div className="form-column one-third-width s-full-width">
          <h5 className="title-5 medium-weight">Vos coordonnées</h5>
          <label className="input-label" htmlFor="nom" id={`${this.props.formName}-name-label`}>
            <p>Prénom et Nom</p>
            <input className={`text-input ${this.props.color}`} ref={(nameInput) => this.nameInput = nameInput}
                    required="required" type="text" name="nom" id={`${this.props.formName}-name-input`}/>  
          </label>
          
        
          <label className="input-label" htmlFor="email" id={`${this.props.formName}-email-label`}>
            <p>Adresse e-mail</p>
            <input className={`text-input ${this.props.color}`} required="required" type="email" 
                    name="email" id={`${this.props.formName}-email-input`}/>
          </label>
          
        
          <label className="input-label" htmlFor="etablissement" id={`${this.props.formName}-company-label`}>
            <p>Établissement</p>
            <input className={`text-input ${this.props.color}`} required="required" type="text" 
                    name="etablissement" id={`${this.props.formName}-company-input`}/>
          </label>

          <PhoneInput color={this.props.color} id={this.props.formName} isRequired/>
        </div>
        <div className="form-column flex-column justc-space-b two-third-width s-full-width">
          <div className="full-width">
            <h5 className="title-5 medium-weight s-mgt-m">Votre message</h5>
            <label className="input-label" htmlFor="message" id={`${this.props.formName}-message-label`}>
              <p>Message</p>
              <textarea className={`text-input textarea ${this.props.color}`} type="text" 
                        required="required" name="message" id={`${this.props.formName}-message-input`}>
              </textarea>  
            </label>
          </div>
          <div className="flex-row justc-space-b alitems-center mgt-s">
            <CustomLink 
              linkType="external-blank"
              linkURL="/donnees-personnelles"
              className={this.props.color === "dark" ? 'white-link' : 'grey-text'}>
              Protection des données personnelles
            </CustomLink>
            <input className="cta fill yellow" type="submit" name="envoyer" value="Envoyer" />
          </div>
        </div>
      </div>
    )
  }
}

export default ContactFormContent;