import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Helmet from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

import Layout from "../components/Layout";
import ContactFormContent from "../components/ContactFormContent";
import CustomLink from "../components/CustomLink";


class ServiceHero extends React.Component {
  render() {
    const content = this.props.page.frontmatter;

    return (
      <div className="flex-column justc-center alitems-center gradient-section overflow-hidden">
        <div className="flex-row alitems-end flex-1 mgt-xl pdt-m padding-horizontal-l l-mgt-m m-mgt-0
                        s-flex-column">
          <div className="api-hero-content flex-column alitems-start half-width pdb-l m-pdb-m
                          s-full-width">
            <h1 className="bold-weight white-text">
              <ReactMarkdown children={content.heroTitle}/>
            </h1>
            <h2 className="text-xlight mgt-m mgb-l l-mgt-s l-mgb-s m-mgb-0">{content.heroDesc}</h2>
            {/* <CustomLink 
                linkType="internal"
                linkURL={content.heroCTA.url}
                className="cta fill blue mgt-xl m-mgt-m">
                {content.heroCTA.label}
            </CustomLink> */}
          </div>
          <div className="half-width api-hero-illu s-full-width">
            <img src={content.heroImg} alt="Sercive main illustration"/>
          </div>
        </div>
      </div>
    )
  }
}

class SellingPoints extends React.Component {
  render() {
    const content = this.props.page.frontmatter;

    return (
      <div className="content-wrapper flex-column alitems-center padding-vertical-m mgb-s l-mgb-0">
        <h3 className="title-4 bold-weight center-text text-medium mgb-xs">
         {content.sellingPointsIntro.title}
        </h3>
        {/* <p className="title-5 text-medium center-text mgb-m">
        {content.sellingPointsIntro.description}
        </p> */}
        <div className="full-width flex-row justc-space-b alitems-start mgt-m l-mgt-s s-flex-column s-alitems-center">
          {content.sellingPoints.map((sellingPoint, index) => (
            <div className="flex-column alitems-center center-text selling-point-wrapper">
              <img className="selling-point-icon" src={sellingPoint.icon} alt={sellingPoint.title} />
              <h4 className="title-5 text-medium medium-weight mgb-xs mgt-xs">{sellingPoint.title}</h4>
              {sellingPoint.content}
            </div>  
          ))}
        </div>
      </div>
    )
  }
}

class ApiTypes extends React.Component {
  render() {
    const content = this.props.page.frontmatter;

    return (
      <div className="gradient-section white-text flex-row">
        <div className="content-wrapper flex-column alitems-center mgt-xl mgb-xl l-mgt-l l-mgb-l">
          <h3 className="title-5 center-text text-xlight">
           {content.apiTypesTitle}
          </h3>
          <div className="full-width flex-row justc-space-b alitems-start mgt-m l-mgt-s wrap s-mgt-0">
            {content.apiTypes.map((type, index) => (
              <div className="flex-column alitems-center half-width s-full-width s-mgt-m">
                <h4 className="api-type-title white-text center-text bold-weight mgb-xs mgt-s">{type.title}</h4>
                <img className="api-type-img mgt-l mgb-l mgr-m mgl-m m-mgt-s m-mgb-s" src={type.image} alt={type.title}/>
                <ReactMarkdown children={type.content} className="arrow-list paragraph-spacing padding-horizontal-m l-padding-horizontal-s"/>
                
              </div>  
            ))}
          </div>
        </div>
      </div>
    )
  }
}


class HowToLaunch extends React.Component {

  render() {
    const content = this.props.page.frontmatter;
    const pdf = content.howToLaunch.conclusion.callToAction.file
    // let downloadLink = ""
    // if(typeof window !== "undefined"){
    //   const data = content.howToLaunch.conclusion.callToAction.file;;
    //   downloadLink = window.URL.createObjectURL(data);
    // }

    return (
      <div className="gradient-light">
        <div className="content-wrapper flex-column alitems-center pdb-xl">
          <h3 className="title-4 bold-weight text-medium center-text mgt-l">
           {content.howToLaunch.title}
          </h3>
          <div className="api-launch-wrapper full-width flex-column justc-space-b alitems-start">
            {content.howToLaunch.steps.map((step, index) => (
              <div className="api-launch-step flex-row alitems-center full-width s-alitems-start">
                <div className="step-title title-4 text-blue text-medium title-desktop">
                  <span>{step.title}</span>
                </div>
                <div className="step-index">
                  <div className="index-pill bold-weight">{index + 1}</div>
                </div>
                <div className="step-content s-flex-column s-alitems-start">
                  <div className="step-title title-4 text-blue text-medium title-mobile">
                    <span>{step.title}</span>
                  </div>
                  {step.content}
                </div>
                
              </div>  
            ))}
          </div>

          <div className="flex-column alitems-center medium-weight mgt-xl">
            {content.howToLaunch.conclusion.text}

              
            <a className="cta fill yellow mgt-s" href={pdf} download="Checklist">
              {content.howToLaunch.conclusion.callToAction.title}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export const ServicesClientsSection = props => {
  const content = props.page.frontmatter;

  return (
    <div>
      <div className="content-wrapper flex-column alitems-center mgb-l mgt-xl">
        <h3 className="title-4 bold-weight text-medium">
          {content.clientsTitle}
        </h3>
      </div>

      <div className="animated-client-list mgb-xl">
        <div className="flex-row no-wrap alitems-center clients-logo-animation">
          {content.serviceClients.map( (client,index) => (
            <img key={index} className="service-client-logo" src={client.image} alt={client.image}/>
          ))}
        </div>
        <div className="flex-row no-wrap alitems-center clients-logo-animation">
          {content.serviceClients.map( (client,index) => (
            <img key={index} className="service-client-logo" src={client.image} alt={client.image}/>
          ))}
        </div>
      </div>  
    </div>
  )
}

export const ServiceContactForm = props => {
  return (
    <form className="flex-column alitems-stretch" 
          name="services-page-contact"
          method="post" 
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
          action="/contact-confirmation/">
        <input type="hidden" name="form-name" value={"services-page-contact" + props.pageTitle} />
        <input type="hidden" name="bot-field" />

        <h3 className="title-5 mgb-m
                       m-mgb-s m-left-text pdr-m">
          Obtenir des informations sur notre offre&nbsp;:
          <span className="blue-underline">{props.contactSubject}</span>
        </h3>

        <input className="hidden" type="text" name="service-name" value={props.contactSubject}/>

        <ContactFormContent formName="service-page-contact" color="light"/>
        <div className="full-width flex-row justc-center mgt-m">
          <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY}/>
        </div>
      </form>
  )
}


class ServiceFeatures extends React.Component {
  constructor(props) {
    super(props)

    this.state = { 
      contactSubject: "",
      contactFormIsDisplayed: ""
    };
  }

  toggleContactForm(e) {
    if (this.state.contactFormIsDisplayed === "displayed") {
      this.setState({contactFormIsDisplayed: ""})
    } else {
      this.setState({contactFormIsDisplayed: "displayed"})
    } 
  }

  escFunction = event => {
    if ((event.keyCode === 27) && (this.state.contactFormIsDisplayed === "displayed")) {
      event.preventDefault();
      this.setState({ contactFormIsDisplayed: "" })
    }}

  setContactSubject(event, type) {
    this.setState({contactSubject: type});
  }

  stopPropagationCustom(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  componentDidUpdate() {
    if (this.state.contactFormIsDisplayed === "displayed") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.body.style.overflow = "visible";
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const content = this.props.page.frontmatter;

    return (
      <div>

        <div className="content-wrapper column alitems-center mgb-xl">  
          <h3 className="title-4 center-text bold-weight text-medium mgt-xl mgb-xl l-mgt-l l-mgb-l">
            {content.addedValue.title}
          </h3>
          <div className="flex-row wrap alitems-stretch justc-space-b">
            {content.addedValue.items.map((item, index) => (
              <div key={index} className="flex-column alitems-center one-third-width s-half-width">
                <div className="api-value-icon">
                  <img src={item.icon} alt='illustration service'/>
                </div>
                <div className="mgl-m mgr-m center-text mgt-s mgb-m l-mgl-s l-mgr-s m-mgl-xs m-mgr-xs m-mgb-s">
                  <ReactMarkdown children={item.text}/>
                </div>
              </div>
            ))}
            <div className="flex-row full-width justc-center">
              <button className="cta fill blue mgt-m"
                      onClick={(e) => {this.setContactSubject(e, content.addedValue.callToAction.mailSubject); 
                                        this.toggleContactForm()}}>
                {content.addedValue.callToAction.title}
              </button>
            </div>
          </div>
        </div>
        <div className={`popup-contact-wrapper ${this.state.contactFormIsDisplayed}`} 
            onClick={(e) => {this.toggleContactForm()}}
            // Prevent Eslint from generating useless warnings while preventing tab index
            role="button" tabIndex="-1" onKeyDown={this.escFunction}>
          <div className="popup-contact-form" onClick={this.stopPropagationCustom}
              // Prevent Eslint from generating useless warnings, while preventing tab index
              role="button" tabIndex="-1" onKeyDown={this.escFunction}>
            <ServiceContactForm contactSubject={this.state.contactSubject} pageTitle={content.title}/>
            <button className="close-contact-form" onClick={(e) => {this.toggleContactForm()}}>
              <span>Fermer</span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}



export const ServicePageTemplate = props => {
  const {page} = props;

  return (
    <div>
      <ServiceHero page={page}/>
      <SellingPoints page={page}/>
      <ApiTypes page={page}/>
      <ServiceFeatures page={page}/>
      <HowToLaunch page={page}/>
      <ServicesClientsSection page={page}/>
    </div>
  );
};

const ServicePage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle},
    },
  } = page;

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <ServicePageTemplate page={page} />
    </Layout>
  );
};

ServicePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicePage;

export const servicePageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heroTitle
        heroDesc
        heroImg
        heroCTA {
          label
          url
        }
        clientsTitle
        serviceClients {
          image
        }
        sellingPointsIntro {
          title
          
        }
        sellingPoints {
          icon
          title
          content
        }
        apiTypesTitle
        apiTypes {
          title
          image
          content
        }
        howToLaunch {
          title
          steps {
            title
            content
          }
          conclusion {
            text
            callToAction {
              title
              file
            }
          }
        }
        addedValue {
          title
          subtitle
          items {
            icon
            text
          }
          callToAction {
            title
            mailSubject
          }
        }
        serviceFeatures {
          illustration
          title
          description
          callToAction {
            title
            mailSubject
          }
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;